import axios from "axios";
import { useEffect, useRef, useState } from "react";
import * as Constants from "../../Constants/index";
import SimpleSlider from "../Helpers/SliderCom";

export default function Banner({ className }) {
  const [banners, setBanners] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [topproducts, setTopproducts] = useState([]);
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    fade: false,
    arrows: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const resp = await axios.get(Constants.api + "banners");
        if (resp.data.success) {
          setBanners(resp.data.banners);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const resp = await axios.get(Constants.api + "settings");
        if (resp.data.success) {
          setSettingData(resp.data.setting);
          localStorage.setItem("settingRow", JSON.stringify(resp.data.setting));
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchIsTopProduct = async () => {
      try {
        const resp = await axios.get(Constants.api + "prices");
        if (resp.data.success) {
          setTopproducts(resp.data.prices);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchIsTopProduct();
  }, []);

  return (
    <>
      {/* TOP PRODUCT IN MARQUE */}
      <div
        className={`w-full bg-white h-20 border-b border-qgray-border trans`}
      >
        <div className="container-fluid mx-auto h-full">
          <div className="flex justify-between items-center h-full">
            <div className="topbar-nav ">
              <div className="track-slide">
                {" "}
                <span className="track-info"></span>Commodity Prices
              </div>
              <ul className="flex space-x-10 marquee">
                {topproducts.length > 0 &&
                  topproducts.map((pRow) => {
                    return (
                      <>
                        <li>
                          <a>
                            <span className="text-xs leading-6 text-qblack font-500">
                              <span className="amount-name">{pRow.title} {" "}{pRow.price}{" "}</span>
                              {/* <span className="amount-text">
                                Product Price :
                              </span> */}
                              {/* <span className="amount-price">
                                {" "}{pRow.price}{" "}
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/arrowBottom.png`}
                                  alt="about"
                                  width="50px"
                                />
                              </span> */}
                            </span>
                          </a>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* END TOP PRODUCT IN MARQUE */}

      {/* SLIDER */}
      <div className={` ${className || ""}`}>
        <div className="flex-row-section">
          {/* <div className="main-text-section">
            <div className="main-wrapper main-text-offer">
              <div className="w-full h-full relative">
                <div className="offer-body">
                  <div className="offer-section-one">
                    {settingData.banner_image_left_url != "" ? (
                      <img
                        src={`${settingData.banner_image_left_url}`}
                        alt="about"
                        className="w-full h"
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/radha-tmt-550-steel-bars-maha-shaktishali-ad-times-of-india-hyderabad-14-02-2019.png`}
                        alt="about"
                        className="w-full h"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="baaner-section">
            <div className="main-wrapper w-[100%] h-full">
              <div className="hero-slider-wrapper xl:h-full xl:mb-0  w-full relative">
                <SimpleSlider settings={settings} selector={sliderRef}>
                  {banners.length > 0 &&
                    banners.map(
                      (banner) =>
                        banner.position === "left" && (
                          <div className="item w-full xl:h-[313px] h-[85px]">
                            <div
                              className="w-full h-full mobile-banner relative"
                              style={{
                                backgroundImage: `url(${banner.image_url})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }}
                            ></div>
                          </div>
                        )
                    )}
                </SimpleSlider>
              </div>
            </div>
          </div>
          <div className="baaner-section">
            <div className="main-wrapper w-[100%] h-full">
              <div className="hero-slider-wrapper xl:h-full xl:mb-0  w-full relative">
                <SimpleSlider settings={settings} selector={sliderRef}>
                  {banners.length > 0 &&
                    banners.map(
                      (banner) =>
                        banner.position === "right" && (
                          <div className="item w-full xl:h-[313px] h-[85px]">
                            <div
                              className="w-full h-full mobile-banner relative"
                              style={{
                                backgroundImage: `url(${banner.image_url})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }}
                            ></div>
                          </div>
                        )
                    )}
                </SimpleSlider>
              </div>
            </div>
          </div>

          {/* <div className="main-text-section">
            <div className="main-wrapper main-text-offer">
              <h1> hjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj</h1>
              <div className="w-full h-full relative">
                <div className="offer-body">
                  <div className="offer-section">
                    {settingData.banner_image_right_one_url != "" ? (
                      <img
                        src={`${settingData.banner_image_right_one_url}`}
                        alt="about"
                        className="w-full h"
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/radha-tmt-550-steel-bars-maha-shaktishali-ad-times-of-india-hyderabad-14-02-2019.png`}
                        alt="about"
                        className="w-full h"
                      />
                    )}
                  </div>

                  <div className="offer-section">
                    {settingData.banner_image_right_two_url != "" ? (
                      <img
                        src={`${settingData.banner_image_right_two_url}`}
                        alt="about"
                        className="w-full h"
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/radha-tmt-550-steel-bars-maha-shaktishali-ad-times-of-india-hyderabad-14-02-2019.png`}
                        alt="about"
                        className="w-full h"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* SLIDER */}
    </>
  );
}
