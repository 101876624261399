import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Constants from "../../../../Constants/index.js";
import Arrow from "../../../Helpers/icons/Arrow";

export default function Navbar({ className, type }) {
  const [productCategories, setProductCategories] = useState([]);
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");

  const loginCustomer = JSON.parse(localStorage.getItem("customer_row")) || [];

  // const getItems = document.querySelectorAll(`.categories-list li`).length;
  // if (categoryToggle && getItems > 0) {
  //   setSize(`${40 * getItems}px`);
  // }
  const handler = () => {
    setToggle(!categoryToggle);
  };
  useEffect(() => {
    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }
  }, [categoryToggle]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const resp = await axios.get(Constants.api + "cetegories");
        if (resp.data.success) {
          setProductCategories(resp.data.categories);
          // console.log(resp.data.categories);
          // console.log('fasdfasdf',productCategories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCategories();
  }, []);

  //console.log(productCategories);
  return (
    <>
      <div
        // className={`nav-widget-wrapper w-full  h-[60px] relative z-30 ${
        //   type === 3 ? "bg-qh3-blue" : "bg-qyellow"
        // }  ${className || ""}`}

        className={`nav-widget-wrapper w-full  h-[60px] relative z-30 bg-qh3-blue`}
      >
        <div className="container-x mx-auto h-full">
          <div className="w-full h-full relative">
            <div className="w-full h-full flex justify-between items-center">
              <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
                <div className="nav">
                  <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                    <li>
                      <Link to="/">
                        <span
                          className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                        >
                          <span>Home</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">
                        <span
                          className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                        >
                          <span>About Us</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <span
                          className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                        >
                          <span>Contact Us</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogs">
                        <span
                          className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                        >
                          <span>Blog</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-products">
                        <span
                          className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                        >
                          <span>Products / Services</span>
                        </span>
                      </Link>
                    </li>
                    {/* <li className="relative">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                      >
                        <span>Products / Services</span>
                        <span className="ml-1.5 ">
                          <Arrow className="fill-current" />
                        </span>
                      </span>
                      <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                        <div
                          className="w-full bg-white flex justify-between items-center "
                          style={{
                            boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                          }}
                        >
                          <div className="categories-wrapper w-full h-full p-5">
                            <div>
                              <div className="category-items">
                                <ul className="flex flex-col space-y-2">
                                  {productCategories.map((pCategory) => {
                                    let url = "/all-products/" + pCategory.id;
                                    return (
                                      <>
                                        <li key={pCategory.id}>
                                          <Link to={url}>
                                            <span
                                              className={`text-qgray text-sm font-400 border-b border-transparent hover:text-qh3-blue hover:border-qh3-blue`}
                                            >
                                              {pCategory.title}
                                            </span>
                                          </Link>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> */}
                    <li className="relative">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer text-white`}
                      >
                        <span>More</span>
                        <span className="ml-1.5 ">
                          <Arrow className="fill-current" />
                        </span>
                      </span>
                      <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                        <div
                          className="w-full bg-white flex justify-between items-center "
                          style={{
                            boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                          }}
                        >
                          <div className="categories-wrapper w-full h-full p-5">
                            <div>
                              <div className="category-items">
                                <ul className="flex flex-col space-y-2">
                                  <li>
                                    <a href="/privacy-policy">
                                      <span
                                        className={`text-qgray text-sm font-400 border-b border-transparent   hover:text-qh3-blue hover:border-qh3-blue`}
                                      >
                                        Privacy Policy
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/terms-condition">
                                      <span
                                        className={`text-qgray text-sm font-400 border-b border-transparent   hover:text-qh3-blue hover:border-qh3-blue`}
                                      >
                                        Terms and Conditions
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/faq">
                                      <span
                                        className={`text-qgray text-sm font-400 border-b border-transparent   hover:text-qh3-blue hover:border-qh3-blue`}
                                      >
                                        FAQ
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/all-products">
                                      <span
                                        className={`text-qgray text-sm font-400 border-b border-transparent   hover:text-qh3-blue hover:border-qh3-blue`}
                                      >
                                        Shop Category Icon
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/all-products">
                                      <span
                                        className={`text-qgray text-sm font-400 border-b border-transparent   hover:text-qh3-blue hover:border-qh3-blue`}
                                      >
                                        Shop List View
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="become-seller-btn">
                <Link to="/become-saller">
                  <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                    <div className="flex space-x-2 items-center">
                      <span className="text-sm font-600">Become a Seller</span>
                      <span>
                        <svg
                          className="fill-current"
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="1.08984"
                            width="6.94106"
                            height="1.54246"
                            transform="rotate(45 1.08984 0)"
                            fill="white"
                          />
                          <rect
                            x="6"
                            y="4.9082"
                            width="6.94106"
                            height="1.54246"
                            transform="rotate(135 6 4.9082)"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </Link>
              </div> */}
              <div className="become-seller-btn">
                {loginCustomer && loginCustomer.id > 0 ? (
                  <>
                    <span className="text-sm  customer-info font-600 spa">
                      {loginCustomer.name} ({loginCustomer.customer_type})
                    </span>

                    <Link to="/logout">
                      <span className="text-sm  btn-login font-600">
                        Logout
                      </span>
                    </Link>

                    {/* <Link to="/logout logut-info">
                      <span className="text-sm  btn-login font-600">
                        Logout
                      </span>
                    </Link> */}
                    {/* <Link to="/logout">
                      <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                        <div className="flex space-x-2 items-center">
                          <span className="text-sm font-600">Logout</span>
                          <span>
                            <svg
                              className="fill-current"
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1.08984"
                                width="6.94106"
                                height="1.54246"
                                transform="rotate(45 1.08984 0)"
                                fill="white"
                              />
                              <rect
                                x="6"
                                y="4.9082"
                                width="6.94106"
                                height="1.54246"
                                transform="rotate(135 6 4.9082)"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </Link> */}
                  </>
                ) : (
                  <Link to="/signup">
                    <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                      <div className="flex space-x-2 items-center">
                        <span className="text-sm font-600 btn-login">
                          Buyer/Enquiry
                        </span>
                        {/* <span>
                          <svg
                            className="fill-current"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1.08984"
                              width="6.94106"
                              height="1.54246"
                              transform="rotate(45 1.08984 0)"
                              fill="white"
                            />
                            <rect
                              x="6"
                              y="4.9082"
                              width="6.94106"
                              height="1.54246"
                              transform="rotate(135 6 4.9082)"
                              fill="white"
                            />
                          </svg>
                        </span> */}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
