import axios from "axios";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import * as Constants from "../../Constants/index";

import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { delCartRow } from "../../redux/action";

import Layout from "../Partials/Layout";

export default function News({ className, type }) {
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [news, setNews] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [modalArticleTitle, setArticleModalTitle] = useState("");
  const [modalArticleDescription, setArticleModalDescription] = useState("");
  const [modalArticleImage, setArticleModalImage] = useState("");
  const [modalArticleDate, setArticleModalDate] = useState("");
  const [visibleArticleModal, setArticleModalVisible] = useState(false);

  const openArticleModal = (title, description, image, created) => {
    setArticleModalTitle(title);
    setArticleModalDescription(description);
    setArticleModalImage(image);
    setArticleModalDate(created);
    setArticleModalVisible(true);
  };

  const productData = useSelector((state) => state.handleCart);
  //const productData = JSON.parse(localStorage.getItem("cart")) || [];
  const dispatch = useDispatch();

  const removeItemRow = (product) => {
    dispatch(delCartRow(product));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const resp = await axios.get(Constants.api + "cetegories");
        if (resp.data.success) {
          setCategories(resp.data.categories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const resp = await axios.get(Constants.api + "articles");
        if (resp.data.success) {
          setArticles(resp.data.articles);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const resp = await axios.get(Constants.api + "news");
        if (resp.data.success) {
          setNews(resp.data.news);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchNews();
  }, []);

  console.log(news);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveone = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function formatTimeAgo(date) {
    const now = new Date();
    const timestamp = new Date(date);
    const elapsed = now - timestamp;

    if (elapsed < 60000) {
      const seconds = Math.floor(elapsed / 1000);
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (elapsed < 3600000) {
      const minutes = Math.floor(elapsed / 60000);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (elapsed < 86400000) {
      const hours = Math.floor(elapsed / 3600000);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(elapsed / 86400000);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const filteredArticles = articles.filter((article) => {
    return (
      selectedCategoryId === null || article.category_id === selectedCategoryId
    );
  });

  const filteredNews = news.filter((singleNews) => {
    return (
      selectedCategoryId === null ||
      singleNews.category_id === selectedCategoryId
    );
  });

  return (
    <>
      <Layout>
        <div
          style={{ boxShadow: " 0px 15px 50px 0px rgba(0, 0, 0, 0.14)" }}
          className={`w-full h-full bg-white border-t-[3px]  ${
            type === 3 ? "border-qh3-blue" : "cart-wrappwer"
          }  ${className || ""}`}
        >
          <div className="container-x mx-auto h-full">
            <div className="w-full h-full">
              <div className="news-info">
                <Carousel responsive={responsive}>
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <img
                        width="50"
                        height="50"
                        src={category.image_url}
                        alt="logo"
                      />
                      {category.title}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="container-x mx-auto h-full">
          <div className="w-full h-full">
            <div className="market-place">
              <h1>Market News Today</h1>
              <div className="body-news">
                <div className="logo-btn">
                  {/* <div className="logo-img">
                    <img
                      width="100"
                      height="36"
                      src={`${process.env.PUBLIC_URL}/assets/images/logo-4.svg`}
                      alt="logo"
                    />
                  </div> */}
                  <div className="btn-view-all">
                    <div>
                      <a href="/all-products">
                        <div class="flex space-x-2 items-center">
                          <p class="text-base font-600 text-qblack">
                            View More
                          </p>

                          <span class="animate-right-dir">
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.0225 6.00243C13.9998 6.03738 13.9772 6.06941 13.9545 6.10436C13.8724 6.10727 13.7904 6.11601 13.7083 6.11601C9.93521 6.11601 6.16215 6.11601 2.38909 6.11601C1.87111 6.11601 1.35313 6.10728 0.835147 6.12475C0.351131 6.14514 0.00863998 6.51501 0.000148475 6.981C-0.00834303 7.45864 0.3483 7.83725 0.837977 7.8722C0.956858 7.88094 1.07857 7.87511 1.20028 7.87511C5.33565 7.87803 9.46818 7.87803 13.6035 7.88094C13.7253 7.88094 13.8498 7.88094 13.9715 7.88094C14.0026 7.93627 14.031 7.9887 14.0621 8.04403C13.9404 8.12267 13.7988 8.18383 13.697 8.28576C12.3355 9.67499 10.9797 11.0671 9.62669 12.4651C9.26155 12.8437 9.25306 13.3767 9.58423 13.732C9.91823 14.0902 10.4419 14.099 10.8127 13.7233C12.7855 11.702 14.7556 9.6779 16.7199 7.64794C17.0907 7.26351 17.0851 6.73053 16.7171 6.34901C14.7697 4.33652 12.8167 2.32987 10.858 0.329035C10.7278 0.195063 10.5466 0.0873038 10.3683 0.0319679C10.0088 -0.0757916 9.63235 0.116428 9.44554 0.451356C9.26438 0.78046 9.31533 1.20859 9.60687 1.51148C10.6768 2.62111 11.7524 3.72492 12.8308 4.82581C13.2271 5.2219 13.6262 5.60925 14.0225 6.00243Z"
                                fill="white"
                              ></path>
                              <path
                                d="M14.0225 6.00241C13.6262 5.60923 13.2243 5.22188 12.8336 4.82288C11.7552 3.72199 10.6796 2.61818 9.60971 1.50855C9.31816 1.20566 9.26721 0.77753 9.44837 0.448427C9.63518 0.113498 10.0116 -0.0787213 10.3711 0.0290382C10.5466 0.0814617 10.7278 0.192134 10.8608 0.326105C12.8195 2.32694 14.7697 4.33359 16.7199 6.34608C17.0879 6.72469 17.0936 7.26058 16.7228 7.64501C14.7584 9.67497 12.7884 11.6991 10.8155 13.7203C10.4475 14.0989 9.92106 14.0873 9.58706 13.7291C9.25589 13.3737 9.26155 12.8408 9.62952 12.4622C10.9825 11.0642 12.3383 9.67206 13.6998 8.28284C13.8017 8.1809 13.9404 8.11974 14.0649 8.0411C14.0338 7.98577 14.0055 7.93334 13.9743 7.87801C13.8526 7.87801 13.7281 7.87801 13.6064 7.87801C9.47101 7.8751 5.33848 7.8751 1.20311 7.87218C1.0814 7.87218 0.962519 7.87801 0.840808 7.86927C0.3483 7.84015 -0.00834304 7.45862 0.00014847 6.98098C0.00863998 6.515 0.351131 6.14512 0.832316 6.12764C1.3503 6.10726 1.86828 6.11891 2.38626 6.11891C6.16215 6.11599 9.93521 6.11599 13.7083 6.11599C13.7904 6.11599 13.8724 6.10726 13.9574 6.10143C13.9772 6.0694 13.9998 6.03445 14.0225 6.00241Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="new-view">
                  <Carousel responsive={responsiveone}>
                    {filteredArticles.map((article) => (
                      <div key={article.id}>
                        <img
                          onClick={() =>
                            openArticleModal(
                              article.title,
                              article.description,
                              article.image_url,
                              article.created_at
                            )
                          }
                          width="100"
                          height="36"
                          src={article.image_url}
                          alt="logo"
                        />

                        <div className="news-text">
                          <div className="text-info">{article.title}</div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="container-x mx-auto h-full latest-news">
              <div className="w-full h-full">
                <div className="latest-news">
                  <div className="latest-marcket-news">
                    <div className="new-left">
                      <h1>Latest Market News</h1>

                      <div className="marcket-btn d-flex">
                        <button>
                          <a class="blue-btn home-goto-cart">Market Dailies</a>
                        </button>
                        <button>
                          <a class="blue-btn home-goto-cart">Insights</a>
                        </button>

                        <button>
                          <a class="blue-btn home-goto-cart">News</a>
                        </button>
                      </div>

                      {filteredNews.map((newsRow) => (
                        <div className="d-flex d-info" key={newsRow.id}>
                          <div className="news-img">
                            <img
                              width="300"
                              height="10"
                              src={newsRow.image_url}
                              alt="logo"
                            />
                          </div>

                          <div className="news-text">
                            <h3>{newsRow.title}</h3>
                            <p>{newsRow.short_description}</p>

                            <p className="d-flex m-r-15">
                              <span>
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="1.5"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                  ></path>
                                </svg>
                                <span className="m-l-15">
                                  {formatTimeAgo(newsRow.created_at)}
                                </span>
                              </span>

                              <span>
                                <img src={newsRow.cat_image_url} />
                                <span className="m-l-15">
                                  {newsRow.cat_name}
                                </span>
                              </span>

                              <span>
                                <span className="m-l-15 btn-market">
                                  {newsRow.news_type}
                                </span>
                              </span>
                            </p>

                            <p className="d-flex m-r-15">
                              <a class="share-btn-whatup">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 24 24"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M19.05 4.91A9.816 9.816 0 0012.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.264 8.264 0 01-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 012.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"></path>
                                </svg>

                                <span className="m-l-15">
                                  {newsRow.total_whatsapp}
                                </span>
                              </a>

                              <a class="share-btn-whatup">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 24 24"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                                  <path d="M13.11 5.72l-.57 2.89c-.12.59.04 1.2.42 1.66.38.46.94.73 1.54.73H20v1.08L17.43 18H9.34a.35.35 0 01-.34-.34V9.82l4.11-4.1M14 2L7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.83C7 18.95 8.05 20 9.34 20h8.1c.71 0 1.36-.37 1.72-.97l2.67-6.15c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66a4.8 4.8 0 00-.88-1.22L14 2zM4 9H2v11h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1z"></path>
                                </svg>

                                <span className="m-l-15">
                                  {newsRow.total_like}
                                </span>
                              </a>
                              <span class="Article__bookmarkWrap--Ksg5S">
                                <a class="Bookmark__btn--uby_g">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-width="1.5"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    height="16"
                                    width="16"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                                    ></path>
                                  </svg>
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}

                      <div class="pagination">
                        <a href="#">Previous</a>
                        <a href="#">1</a>
                        <a class="active" href="#">
                          2
                        </a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#">5</a>
                        <a href="#">6</a>
                        <a href="#">Next</a>
                      </div>
                    </div>

                    <div className="news-right">
                      <h1>Raw Materials Prices</h1>
                      <div className="d-flex d-info flex-wrap">
                        <div className="price-info mr-2">
                          <h4>
                            <b>
                              ₹59,300/<span className="mt">MT</span>
                            </b>
                          </h4>
                        </div>
                        <div className="time-ago">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                            <path d="M12 12h-3.5"></path>
                            <path d="M12 7v5"></path>
                          </svg>
                          <span className="m-l-15">17 hours ago</span>
                        </div>

                        <div className="location-info">
                          <img src="https://d5nfy7n2jvqm2.cloudfront.net/buyerfe/news_icon_location.svg" />
                          <span className="m-l-15">Ludhiana</span>
                        </div>

                        <div className="location-info">
                          <img src="https://d5nfy7n2jvqm2.cloudfront.net/buyerfe/news_icon_flag.svg" />
                          <span className="m-l-15">OFB</span>
                        </div>

                        <div className="location-info">
                          <img src="https://d5nfy7n2jvqm2.cloudfront.net/buyerfe/news_icon_star.svg" />
                          <span className="m-l-15">HRC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card flex justify-content-center">
          <Dialog
            visible={visibleArticleModal}
            onHide={() => setArticleModalVisible(false)}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          >
            <img
              onClick={() => setArticleModalVisible(true)}
              width="200"
              height="200"
              src={modalArticleImage}
              alt="logo"
            />
            <div className="text-modal">
              <div className="modal-body-info news-text">
                <h3>{modalArticleTitle}</h3>
                <p>{modalArticleDescription}</p>
                <span>{formatTimeAgo(modalArticleDate)}</span>
              </div>
            </div>
          </Dialog>
        </div>
      </Layout>
    </>
  );
}
