import { combineReducers } from "redux";
import handleCart from "./handleCart";
import { singleProduct } from "./singleProductReducer";
import { topSearchProduct } from "./topSearchProductReducer";
const rootReducers = combineReducers({
  handleCart,
  singleProduct,
  topSearchProduct,
});
export default rootReducers;
