import React, { useEffect, useState } from "react";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Youtube from "../../../Helpers/icons/Youtube";
export default function Footer({ type }) {
  const currentYear = new Date().getFullYear();

  const [settingData, setSettingData] = useState(null);
  useEffect(() => {
    const storedSetting = localStorage.getItem("settingRow");
    if (storedSetting) {
      setSettingData(JSON.parse(storedSetting));
    }
  }, []);

  return (
    <footer className="footer-section-wrapper footer-bg print:hidden">
      <div className="container-x block mx-auto pt-[20px]">
        <div className="w-full flex flex-col items-center mb-[10px]">
          {/* logo area */}
          <div className="mb-[10px]">
            <a href="/">
              <img
                width="152"
                height="36"
                src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
                alt="logo"
                className="footer-logo"
              />
            </a>
          </div>
          <div className="w-full h-[1px] bg-[#E9E9E9]"></div>
        </div>
        <div className="lg:flex justify-between mb-[10px]">
          <div className="lg:w-1/4 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0">
            <div>
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#fff]">
                  <b>FEATURE</b>
                </h6>
              </div>
              <div className="footer-link-all">
                <ul className="flex flex-col space-y-4">
                  <li>
                    <a href="/terms-condition">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Terms Condition
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Contact Us
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        General Enquiries
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Supplier Enquiries
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Partner Enquiries
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Investor Relations
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="/faq">
                      <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        FAQ
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:w-1/4 w-full mb-10 lg:mb-0">
            <div className="mb-5">
              <h6 className="text-[18] font-500 text-[#fff]"><b>FEATURE</b></h6>
            </div>
            <div>
              <ul className="flex flex-col space-y-4 ">
                <li>
                  <a href="/about">
                    <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      About Us
                    </span>
                  </a>
                </li>
                <li>
                  <a href="/teams">
                    <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Our Teams
                    </span>
                  </a>
                </li>
                <li>
                  <a href="/blogs">
                    <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      Blogs
                    </span>
                  </a>
                </li>
                <li>
                  <a href="/news">
                    <span className="text-[#fff] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                      News
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:w-1/4 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 w-100">
            <div>
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#fff]">
                  <b>SOCIAL LINK</b>
                </h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-4 ">
                  <li>
                    <span className="text-[#fff] text-[15px]">
                      <b>Address: </b>
                      {settingData?.company_address}
                    </span>
                  </li>
                  <li>
                    <span className="text-[#fff] text-[15px]">
                      <b>Email: </b>
                      {settingData?.email}
                    </span>
                  </li>
                  <li>
                    <span className="text-[#fff] text-[15px]">
                      <b>Mobile: </b>
                      {settingData?.mobile}
                    </span>
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <div className="lg:w-1/4 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 w-100">
            <div>
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#fff]">
                  <b>STEELWALA</b>
                </h6>
              </div>
              <div className="flex space-x-5 items-center mt-5 social-icon">
                <a href="#">
                  <Instagram className="fill-current text-qwhite hover:text-qblack" />
                </a>
                <a href="#">
                  <Facebook className="fill-current text-qwhite hover:text-qblack" />
                </a>
                <a href="#">
                  <Youtube className="fill-current text-qwhite hover:text-qblack" />
                </a>
              </div>
            </div>
          </div>

        </div>
        <div className="bottom-bar border-t border-qwhite-border lg:h-[82px] lg:flex justify-center items-center">
          <div className="flex lg:space-x-5 justify-center items-center mb-3">
            <span className="sm:text-base text-[10px] font-300"
              style={{ color: "#fff" }}>
              ©{currentYear}
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
                className="font-500  mx-1"
                style={{ color: "#fff" }}
              >
                STEELWALA
              </a>
              All rights reserved
            </span>
          </div>
          {/* <div className="">
            <a href="#">
              <img
                width="318"
                height="28"
                src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}
                alt="payment-getways"
              />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
