import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../../Constants/index.js";

export default function SearchBox({ className, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSuggestions() {
      if (searchTerm.trim() !== "") {
        try {
          const response = await axios.get(
            Constants.api + `search-products?search=${searchTerm.trim()}`
          );
          if (response.data.success) {
            setSuggestions(response.data.search_products);
            setLoading(false);
          } else {
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    }

    fetchSuggestions(); // Call the async function
  }, [searchTerm]);

  const handleSelectProduct = (productId) => {
    const selectedProduct = suggestions.find(
      (product) => product.id === productId
    );
    if (selectedProduct) {
      setSearchTerm(selectedProduct.title);
      setSuggestions([]);
      navigate(`/single-product?PID=${productId}`);
    }
  };

  const handleSearchChange = (event) => {
    setLoading(true);
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div
        className={`w-full h-full flex items-center  border border-qgray-border bg-white ${
          className || ""
        }`}
      >
        <div className="flex-1 bg-red-500 h-full">
          <form action="#" className="h-full">
            <input
              type="text"
              className="search-input"
              placeholder="Search Product..."
              value={searchTerm} // Bind the input value to the state
              onChange={handleSearchChange} // Set up an event handler for changes
            />
            {loading && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
            <ul className="suggestions-menu">
              {suggestions.length > 0
                ? suggestions.map((product) => (
                    <li
                      key={product.id}
                      onClick={() => handleSelectProduct(product.id)}
                      className="suggestion-item"
                    >
                      <span className="title">{product.title}</span>
                      {/* <span className="price">{product.price}</span> */}
                    </li>
                  ))
                : null}
            </ul>
          </form>
        </div>
        {/* <div className="w-[1px] h-[22px] bg-qgray-border"></div>
        <div className="flex-1 flex items-center px-4">
          <button
            type="button"
            className="w-full text-xs font-500 text-qgray flex justify-between items-center "
          >
            <select>
              <option value="product">Product</option>
              <option value="seller">Seller</option>
            </select>
            <span>Product</span>
            <span>
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="9.18359"
                  y="0.90918"
                  width="5.78538"
                  height="1.28564"
                  transform="rotate(135 9.18359 0.90918)"
                  fill="#8E8E8E"
                />
                <rect
                  x="5.08984"
                  y="5"
                  width="5.78538"
                  height="1.28564"
                  transform="rotate(-135 5.08984 5)"
                  fill="#8E8E8E"
                />
              </svg>
            </span>
          </button>
        </div>
        <button
          className={` w-[93px] h-full text-sm font-600 btn-searchs ${
            type === 3 ? "bg-qh3-blue text-white" : "search-btn"
          }`}
          type="button"
          onClick={() => {
            navigate("/all-products");
            dispatch(setTopSearchProduct(searchProductData));
          }}
        >
          Search
        </button> */}
      </div>
    </>
  );
}
