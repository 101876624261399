import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../../../../Constants/index.js";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";

import { useSelector } from "react-redux";

export default function HeaderOne({ className, drawerAction, type = 3 }) {
  const productData = useSelector((state) => state.handleCart);
  //const productData = JSON.parse(localStorage.getItem("cart")) || [];
  const dispatch = useDispatch();
  const loginCustomer = JSON.parse(localStorage.getItem("customer_row")) || [];


  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSuggestions() {
      if (searchTerm.trim() !== "") {
        try {
          const response = await axios.get(
            Constants.api + `search-products?search=${searchTerm.trim()}`
          );
          if (response.data.success) {
            setSuggestions(response.data.search_products);
            setLoading(false);
          } else {
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    }

    fetchSuggestions(); // Call the async function
  }, [searchTerm]);

  const handleSelectProduct = (productId) => {
    const selectedProduct = suggestions.find(
      (product) => product.id === productId
    );
    if (selectedProduct) {
      setSearchTerm(selectedProduct.title);
      setSuggestions([]);
      navigate(`/single-product?PID=${productId}`);
    }
  };

  const handleSearchChange = (event) => {
    setLoading(true);
    setSearchTerm(event.target.value);
  };


  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      {/* <TopBar className="quomodo-shop-top-bar" /> */}
      <Middlebar
        type={type}
        className="quomodo-shop-middle-bar lg:block hidden"
      />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            <Link to="/home">
              <img
                width="152"
                height="36"
                src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
                alt="logo"
              />
            </Link>
          </div>
          <div className="login-mobile">
            {loginCustomer && loginCustomer.id > 0 ? (
              <Link to="/profile">
                <span className="text-sm font-600 spa">
                  {loginCustomer.name} ({loginCustomer.customer_type})
                </span>
              </Link>
            ) : (
              <Link to="/signup">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/user (1).png`}
                  alt="logo"
                />
              </Link>
            )}
          </div>

          <div className="cart relative cursor-pointer">
            <Link to="/cart">
              <span>
                <ThinBag />
              </span>
            </Link>

            <span
              className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] bg-qh3-blue text-white`}
            >
              {productData.length}
            </span>
          </div>
        </div>
      </div>
      <div className="mobile-search h-full">
        <form action="#" className="h-full">
          <input
            type="text"
            className="search-input"
            placeholder="Search Product..."
            value={searchTerm} // Bind the input value to the state
            onChange={handleSearchChange} // Set up an event handler for changes
          />
          {loading && (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          )}
          <ul className="suggestions-menu">
            {suggestions.length > 0
              ? suggestions.map((product) => (
                <li
                  key={product.id}
                  onClick={() => handleSelectProduct(product.id)}
                  className="suggestion-item"
                >
                  <span className="title">{product.title}</span>
                  {/* <span className="price">{product.price}</span> */}
                </li>
              ))
              : null}
          </ul>
        </form>
      </div>
      <Navbar type={type} className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}
