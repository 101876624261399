import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TeamCard({ className, datas }) {
  return (
    <div
      className={`team-hover blog-card-wrapper w-full border border-[#D3D3D3] relative ${
        className || ""
      }`}
    >
      <div className="img w-full h-[350px]">
        <img
          src={`${datas.image_url}`}
          alt="team image"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-[20px]">
        <div className="details text-center">
          <h1 className="text-[22px] text-qblack font-semibold line-clamp-2 capitalize">
            {datas.name}
          </h1>
          <p className="text-qgraytwo text-[13px] line-clamp-2">
            <h1 className="text-[15px] text-qblack font-semibold line-clamp-2 capitalize">
              {datas.qualification}
            </h1>
          </p>
          <p className="text-qgraytwo text-[13px]">{datas.university}</p>
          <p className="text-qgraytwo text-[13px]">{datas.designation}</p>
          <p className="text-qgraytwo text-[13px]">{datas.company}</p>
        </div>
      </div>
      <a href="https://www.linkedin.com/" className="linkedin-link">
        <div className="flex items-center group">
          <FontAwesomeIcon
            icon={faLinkedin}
            className="text-blue-500 text-2xl group-hover:inline"
          />
        </div>
      </a>
    </div>
  );
}
