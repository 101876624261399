import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { resetCart } from "../../../redux/action";

const Logout = ({ customerId, onLogout }) => {
  const dispatch = useDispatch();

  localStorage.removeItem(`customer_row`);
  localStorage.removeItem(`isCustomerLoggedIn`);
  localStorage.removeItem(`cart`);
  dispatch(resetCart());
  return <Navigate to="/login" />;
};

export default Logout;
